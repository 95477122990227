.navbar {
	position: sticky;
	top: 0px;
	z-index: 999;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100px;
	margin-top: 0px;
	padding-right: 100px;
	padding-left: 100px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
	box-shadow: 0 0 10px -6px #000;
}

.topnavigationlink {
	margin-right: 11px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Rubik, sans-serif;
	color: #fff;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
}

.topnavigationlink:hover,
.topnavigationlink.active {
	color: #fff;
	border-bottom: 1px solid #fff;
}

.login {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 350px;
	height: auto;
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
	padding: 0px;
	float: right;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	border-radius: 0px;
	background-color: transparent;
	color: #5d5d5d;
}

.login-form-wrapper {
	margin-bottom: 0px;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.login-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.login-id-field {
	width: 125px;
	height: 25px;
	margin-right: 10px;
	margin-bottom: 0px;
	padding: 3px 10px;
	float: left;
	border-style: solid;
	border-width: 1px;
	border-color: #002f55;
	font-family: Karla, sans-serif;
	font-size: 0.6875rem;
	line-height: 22px;
}

.login-button {
	position: static;
	width: 75px;
	height: 25px;
	padding: 0px 10px 0px 26px;
	float: left;
	border-radius: 4px;
	background-color: #002f55;
	background-image: url('/20170713122722/assets/images/Padlock.svg');
	background-position: 12% 45%;
	background-size: 9px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Rubik, sans-serif;
	color: #fff;
	font-size: 0.75rem;
	line-height: 22px;
	font-weight: 400;
	text-align: center;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.login-button:hover {
	background-color: #eb8228;
}

.topnavigationmenu {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.login-links-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.loginlink {
	margin-top: -5px;
	margin-bottom: -2px;
	margin-left: 10px;
	font-family: Karla, sans-serif;
	color: #fff;
	font-size: 0.5625rem;
}

.loginlink:hover {
	color: #fff;
}

.slide {
	background-position: 0% 18%;
	background-repeat: no-repeat;
}

.slide.slide1 {
	background-position: 50% 30%;
	background-size: cover;
}

.slide.slide2 {
	background-position: 50% 30%;
	background-size: cover;
}

.mask {
	height: 550px;
}

.topnavigationholder {
	position: static;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	z-index: 999;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 75px;
	padding-right: 100px;
	padding-left: 100px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #002f55;
	background-image: linear-gradient(150deg, #002f55, #69c);
}

.nav-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-top: 5px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.nav-link,
.nav-link:hover {
	margin-right: auto;
	margin-left: auto;
	padding-top: 10px;
	padding-bottom: 5px;
	float: right;
	font-family: Rubik, sans-serif;
	color: #002f55;
	font-size: 1rem;
	text-align: center;
}

.nav-link:hover {
	border-bottom: 5px none #000;
}

.nav-link.mobileonly {
	display: none;
}

.nav-link.megalink {
	margin-left: 0px;
	padding: 5px 40px 5px 0px;
	color: #fff;
	text-align: left;
}

.nav-link.megalink:hover {
	border-bottom-style: none;
	text-decoration: underline;
}

.nav-link.megalink.submega {
	margin-left: 0px;
	padding-top: 0px;
	padding-bottom: 2px;
	color: #ddd;
	font-size: 0.875rem;
	text-align: left;
}

.brand {
	width: 335px;
	margin-top: 2px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.slider {
	height: 550px;
}

.header-search-container {
	position: absolute;
	left: auto;
	top: 100%;
	right: 0px;
	bottom: auto;
	z-index: 999;
	display: none;
	overflow: hidden;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
}

.search-form-wrap {
	position: relative;
	z-index: 100;
	display: block;
	width: 380px;
	padding-top: 0rem;
	padding-right: 0rem;
	padding-left: 0rem;
	float: right;
	background-color: #dbdbdb;
}

.search-form {
	height: 2.5rem;
	margin-bottom: 0px;
}

.search-field {
	width: 75%;
	height: 2.5rem;
	margin-bottom: 0px;
	float: left;
	font-family: Karla, sans-serif;
}

.search-link {
	width: 25%;
	height: 2.5rem;
	float: right;
	background-color: #002f55;
	-webkit-transition: all 450ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 450ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	font-family: Rubik, sans-serif;
	letter-spacing: 1px;
	text-transform: uppercase;
	cursor: pointer;
}

.search-link:hover {
	padding-left: 25px;
	background-color: #eb8228;
	font-family: Rubik, sans-serif;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.searchicon {
	display: block;
	width: 20px;
	height: 20px;
	margin-left: 15px;
	float: right;
	background-image: url('/20170713122722/assets/images/search_navy.svg');
	background-position: 50% 50%;
	background-size: 20px;
	background-repeat: no-repeat;
	cursor: pointer;
}

.searchicon.mobilesearchicon {
	display: none;
}

.icon-2 {
	margin-top: 225px;
	font-size: 1.625rem;
}

.icon-3 {
	margin-top: 225px;
	font-size: 1.625rem;
}

.iconboxes {
	margin-top: 0px;
	margin-bottom: 0px;
	padding-right: 10%;
	padding-left: 10%;
	background-image: -webkit-gradient(linear, left bottom, left top, from(#fff), to(hsla(0, 0%, 100%, 0))), url('/20170713122722/assets/images/Side-Explosions-Right-01.svg'), url('/20170713122722/assets/images/Side-Explosions-01.svg');
	background-image: linear-gradient(0deg, #fff, hsla(0, 0%, 100%, 0)), url('/20170713122722/assets/images/Side-Explosions-Right-01.svg'), url('/20170713122722/assets/images/Side-Explosions-01.svg');
	background-position: 0px 0px, 100% 50%, 0% 50%;
	background-size: auto, auto, auto;
	background-repeat: repeat, no-repeat, no-repeat;
}

.iconboxholder {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0px;
	padding-top: 60px;
	padding-bottom: 2rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.iconbox {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 30%;
	margin-bottom: 20px;
	padding: 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-radius: 4px;
	background-color: transparent;
	-webkit-transition: all 450ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 450ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.iconbox:hover {
	border-radius: 4px;
	background-color: #fff;
	box-shadow: 0 8px 10px -7px rgba(0, 0, 0, 0.75);
}

.iconboxtitle {
	margin-top: 10px;
	font-family: Rubik, sans-serif;
	color: #002f55;
	font-size: 1.375rem;
	font-weight: 500;
}

.iconboxblurb {
	margin-top: 10px;
	font-family: Karla, sans-serif;
	font-size: 1rem;
	text-align: center;
}

.iconboxbuttons {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 10px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.generalbutton {
	height: auto;
	margin-top: 20px;
	margin-right: 5px;
	margin-left: 5px;
	border-radius: 4px;
	background-color: #002f55;
	-webkit-transition: all 450ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 450ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	font-family: Rubik, sans-serif;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.generalbutton:hover {
	background-color: #128fd7;
	-webkit-transform: scale(1.01);
	-ms-transform: scale(1.01);
	transform: scale(1.01);
	color: #fff;
}

.generalbutton.announcementbutton {
	margin-left: 0px;
	background-color: #557630;
}

.generalbutton.announcementbutton:hover {
	background-color: #6a9f30;
}

.generalbutton.creditcards {
	background-color: #b65e11;
	text-align: center;
}

.generalbutton.creditcards.leftaligned {
	margin-left: 0px;
}

.generalbutton.creditcards.leftaligned:hover {
	background-color: #002f55;
}

.generalbutton.creditcards.whychoosebutton {
	margin-left: 0px;
}

.generalbutton.creditcards.whychoosebutton:hover {
	background-color: #002f55;
}

.generalbutton.autoloans {
	background-color: #002f55;
	text-align: center;
}

.generalbutton.mortgages {
	background-color: #557630;
	text-align: center;
}

.generalbutton.personalloans {
	background-color: #557630;
	text-align: center;
}

.generalbutton.checking {
	background-color: #9c6a07;
	text-align: center;
}

.generalbutton.savings {
	text-align: center;
}

.generalbutton.savings:hover {
	background-color: #002f55;
}

.generalbutton.blueleftalign {
	margin-top: 0px;
	margin-left: 10px;
}

.generalbutton.blueleftalign:hover {
	background-color: #128fd7;
}

.creditcard {
	width: 50%;
	margin-top: 0px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	overflow: hidden;
}

.announcements {
	padding: 2rem 10% 1rem;
	background-color: #128fd7;
	background-image: url('/20170713122722/assets/images/White-Explosions-Right-01.svg'), linear-gradient(135deg, #002f55, #128fd7);
	background-position: 0% 100%, 0px 0px;
	background-size: cover, auto;
	background-repeat: no-repeat, repeat;
	box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, 0.5);
}

.announcementsholder {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0px;
	margin-bottom: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.announcement {
	display: block;
	width: 30%;
	margin-bottom: 20px;
	padding: 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 4px;
	background-color: #fff;
	box-shadow: 1px 1px 10px -7px #000;
	-webkit-transition: all 450ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 450ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.announcement:hover {
	background-color: #fff;
	box-shadow: 4px 4px 15px -10px #000;
}

.announcementtitle {
	width: 100%;
	margin-top: 10px;
	margin-bottom: 5px;
	font-family: Rubik, sans-serif;
	color: #002f55;
	font-size: 1.125rem;
}

.announcementblurb {
	width: 100%;
	margin-bottom: 20px;
	font-family: Karla, sans-serif;
	font-size: 1rem;
}

.image-2 {
	width: 100%;
}

.dropdownmegamenu {
	position: absolute;
	left: 0%;
	top: 100px;
	right: 0%;
	bottom: auto;
	z-index: 900;
	display: none;
	width: auto;
	height: auto;
	padding-top: 10px;
	padding-right: 60px;
	padding-bottom: 10px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-align-content: center;
	-ms-flex-line-pack: center;
	align-content: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-top: 1px none #000;
	border-bottom: 1px none #ebebee;
	background-color: transparent;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#002f55), to(rgba(0, 47, 85, 0.9)));
	background-image: linear-gradient(180deg, #002f55, rgba(0, 47, 85, 0.9));
	opacity: 0;
}

.submegamenu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.navlinkunderline {
	position: static;
	left: 0%;
	top: auto;
	right: 0%;
	bottom: 0%;
	width: 0px;
	height: 4px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-radius: 2px;
	background-color: #eb8228;
}

.navlinkholder {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	margin-right: 10px;
	margin-left: 25px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	background-color: transparent;
}

.selectedtriangle {
	position: absolute;
	left: auto;
	top: -10px;
	right: 525px;
	width: 20px;
	height: 12px;
	background-color: transparent;
	background-image: url('/20170713122722/assets/images/blue-arrow-2.svg');
	background-position: 50% 50%;
	background-size: 20px;
	background-repeat: no-repeat;
	opacity: 1;
}

.savenavlink {
	color: #002f55;
}

.slideshowexplosion {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 40%;
	height: 100%;
	margin-bottom: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: url('/20170713122722/assets/images/Dk-Blue-Explosions-01.svg');
	background-position: 0% 50%;
	background-size: auto 125%;
	background-repeat: no-repeat;
}

.heading {
	margin-top: 0px;
	margin-bottom: 10px;
	font-family: Rubik, sans-serif;
	color: #fff;
	font-size: 2.25rem;
	line-height: 37px;
	font-weight: 500;
	text-shadow: 1px 1px 6px #002f55;
}

.text-block-3 {
	border-style: none;
	border-width: 1px;
	border-color: #002f55;
	font-family: Karla, sans-serif;
	color: #fff;
	font-size: 1.25rem;
	line-height: 24px;
	text-shadow: 1px 1px 6px #002f55;
}

.captionholder {
	width: 65%;
	margin-bottom: 25px;
	margin-left: 100px;
	padding: 25px;
	border-radius: 25px;
	background-color: rgba(0, 47, 85, 0.5);
}

.slideshowbutton {
	margin-top: 20px;
	border-radius: 4px;
	background-color: #557630;
	box-shadow: 1px 1px 3px 0 rgba(0, 47, 85, 0.65);
	-webkit-transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	font-family: Rubik, sans-serif;
	font-weight: 500;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.slideshowbutton:hover {
	padding-top: 9px;
	padding-bottom: 9px;
	background-color: #6a9f30;
	color: #fff;
	box-shadow: 1px 1px 5px 3px rgba(0, 47, 85, 0.55);
}

.slide-nav {
	background-color: transparent;
	color: transparent;
}

.scrollingloginholder {
	position: fixed;
	left: auto;
	top: -30px;
	right: 183px;
	bottom: auto;
	z-index: 1000;
	width: 75px;
	padding-right: 0px;
	padding-left: 0px;
}

.button-2 {
	width: 75px;
	height: 30px;
	padding: 5px 10px 0px 26px;
	border-radius: 0px 0px 4px 4px;
	background-color: #002f55;
	background-image: url('/20170713122722/assets/images/Padlock.svg');
	background-position: 12% 50%;
	background-size: 9px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Rubik, sans-serif;
	color: #fff;
	font-size: 0.75rem;
	line-height: 22px;
	text-align: center;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.button-2:hover {
	color: #fff;
	background-color: #eb8228;
}

.image-3 {
	width: 50px;
	height: 50px;
}

.image-4 {
	width: 50px;
	height: 50px;
}

.image-5 {
	width: 50px;
	height: 50px;
}

.image-6 {
	width: 65px;
	height: 50px;
}

.image-7 {
	width: 50px;
	height: 50px;
}

.image-8 {
	width: 50px;
	height: 50px;
}

.testimonials {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	padding: 3rem 10%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #435d25;
	background-image: url('/20170713122722/assets/images/Green-Explosions2-01.svg'), linear-gradient(315deg, #83c33c, #435d25);
	background-position: 0px 0px, 0px 0px;
	background-size: auto, auto;
	background-repeat: no-repeat, repeat;
}

.testimonials.homebox {
	overflow: hidden;
}

.headshot {
	width: 220px;
	height: 220px;
	margin-right: 40px;
	border-style: solid;
	border-width: 5px;
	border-color: hsla(0, 0%, 100%, 0.6);
	border-radius: 50%;
	background-image: url('/20170713122722/assets/images/Swanson.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	box-shadow: 1px 1px 3px 0 #557630;
}

.testimonialholder {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0%;
	padding-left: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.testimonial {
	width: 60%;
}

.testimonialblurb {
	margin-bottom: 20px;
}

.testimonialnamebuttonholder {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.testimoinalname {
	padding-top: 10px;
	border-top: 2px solid #fff;
}

.testimonialbutton {
	display: block;
}

.text-block-4 {
	font-family: Karla, sans-serif;
	color: #fff;
	font-size: 1.125rem;
	line-height: 23px;
	text-shadow: 1px 1px 6px #557630;
}

.text-block-5 {
	font-family: Rubik, sans-serif;
	color: #fff;
	font-size: 1rem;
	font-weight: 500;
	text-shadow: 1px 1px 6px #557630;
}

.whychooseus {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 450px;
	padding: 0rem 0% 0rem 10%;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
}

.locations {
	display: block;
	height: auto;
	margin-bottom: 0px;
	padding: 2rem 10%;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: #002f55;
}

.featurednews {
	height: auto;
	margin-left: 0px;
	padding: 1rem 10% 3rem;
	background-color: #fff;
}

.featurednewsheader {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 10px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.featurednewsline {
	height: 2px;
	margin-left: 10px;
	padding-left: 0px;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: #eb8228;
}

.featurednewsline.whiteline {
	background-color: #fff;
}

.featurednewscontent {
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.news-cb {
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.featurednewsimage {
	width: 50%;
	height: auto;
}

.text-block-6 {
	margin-top: 0px;
	font-family: Rubik, sans-serif;
	color: #002f55;
	font-size: 1.375rem;
	line-height: 24px;
	font-weight: 500;
}

.text-block-7 {
	margin-top: 10px;
	font-family: Karla, sans-serif;
	font-size: 1rem;
}

.featurednewsblurbholder {
	width: 50%;
	padding-left: 20px;
}

.subscribeandsocial {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	padding: 1.5rem 10% 2rem;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: #b65e11;
	background-image: url('/20170713122722/assets/images/White-Explosion-01.svg'), linear-gradient(150deg, #eb8228, #f6b32d);
	background-position: 50% 0%, 0px 0px;
	background-size: cover, auto;
	background-repeat: no-repeat, repeat;
}

.image-10 {
	min-width: 100%;
	width: auto;
}

.blurbemailbutton {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.text-block-8 {
	font-family: Karla, sans-serif;
	color: #fff;
	font-size: 1.25rem;
	text-shadow: 1px 1px 6px #5f2d02;
}

.whiteheadingthree {
	margin-top: 0px;
	margin-bottom: 0px;
	font-family: Rubik, sans-serif;
	color: #fff;
	font-weight: 500;
	text-shadow: 1px 1px 6px #5f2d02;
}

.blueheading3 {
	margin-top: 0px;
	margin-bottom: 0px;
	font-family: Rubik, sans-serif;
	color: #002f55;
	font-weight: 500;
}

.subscribesubmitinfo {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-top: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.subscribeinfo {
	width: auto;
	margin-bottom: 1rem;
}

.text-field {
	margin-top: 0px;
	margin-bottom: 0px;
	font-family: Karla, sans-serif;
}

.form-block {
	width: 100%;
	margin-bottom: 0px;
}

.subscribetoemails {
	width: 50%;
	margin-right: auto;
	margin-left: auto;
}

.social-link {
	width: 50px;
	height: 50px;
	margin-top: 20px;
	margin-right: 0.25rem;
	margin-left: 0rem;
	padding-top: 9px;
	padding-right: 0.15rem;
	padding-left: 0.15rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 1px none transparent;
	border-radius: 50%;
	background-color: #002f55;
	font-size: 0.75rem;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.02rem;
	text-decoration: none;
	text-transform: uppercase;
}

.social-link:hover {
	margin-top: 0px;
	background-color: #128fd7;
}

.social-link.linkedin {
	width: 32px;
	height: 32px;
	margin-top: 0px;
	padding-top: 2px;
	background-color: #128fd7;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.social-link.linkedin:hover {
	background-color: #eb8228;
}

.social-link.facebook {
	width: 32px;
	height: 32px;
	margin-top: 0px;
	padding: 2px 1rem 0px 0.25rem;
	background-color: #128fd7;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.social-link.facebook:hover {
	background-color: #eb8228;
}

.social-link.twitter {
	width: 32px;
	height: 32px;
	margin-top: 0px;
	padding-top: 2px;
	background-color: #128fd7;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.social-link.twitter:hover {
	background-color: #eb8228;
}

.social-link.instagram {
	width: 32px;
	height: 32px;
	margin-top: 0px;
	padding-top: 2px;
	background-color: #128fd7;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.social-link.instagram:hover {
	background-color: #eb8228;
}

.social-icon {
	width: 22px;
	height: 29px;
	max-width: none;
	margin-bottom: 0px;
}

.twitter-icon {
	margin-left: 1px;
	margin-top: 1px;
}

.facebook-icon {
	margin-left: -2px;
	margin-top: -1px;
}

.linkedIn-icon {
	margin-top: -1px;
}

.heading-3 {
	margin-top: 0px;
	background-color: transparent;
	font-family: Rubik, sans-serif;
	color: #fff;
	font-weight: 500;
}

.locationsholder {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.officelocation {
	font-family: Rubik, sans-serif;
	color: #fff;
	font-size: 1rem;
}

.officeinformation {
	font-family: Karla, sans-serif;
	color: #dbdbdb;
}

.locationblock {
	width: auto;
}

.footer-signoff-link-wrap {
	display: inline-block;
	margin-top: 0px;
}

.footer-signoff-link {
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	font-family: Karla, sans-serif;
	color: #fff;
	font-size: 0.8rem;
	letter-spacing: 1px;
	text-decoration: none;
}

.footer-signoff-link:hover {
	color: #bebebe;
}

.footer-copyright {
	display: inline-block;
	margin-right: 1.5rem;
	margin-bottom: 0rem;
	font-family: Karla, sans-serif;
	color: #fff;
	font-size: 0.9rem;
}

.sign-off-div-wrap {
	width: 100%;
	min-width: 991px;
	padding-right: 10%;
	padding-left: 10%;
}

.signoff-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	margin-bottom: 0px;
	padding-top: 0rem;
	padding-bottom: 1.5rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-top: 1px none hsla(0, 0%, 100%, 0.5);
	background-color: #002f55;
	background-image: -webkit-gradient(linear, left bottom, left top, from(#000), to(#002f55));
	background-image: linear-gradient(0deg, #000, #002f55);
}

.footer-signoff-links {
	display: inline-block;
	margin-right: 1rem;
	border-bottom: 2px solid hsla(0, 0%, 100%, 0.25);
	line-height: 1.25rem;
}

.footer-signoff-links:hover {
	border-bottom-color: transparent;
}

.creditcardinfoholder {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	overflow: hidden;
}

.text-block-9 {
	margin-bottom: 20px;
	font-family: Rubik, sans-serif;
	color: #002f55;
	font-size: 1.75rem;
	line-height: 32px;
}

.creditcardheader {
	width: 50%;
}

.creditcardunderline {
	width: 18%;
	height: 3px;
	margin-top: 20px;
	margin-bottom: 20px;
	background-color: #128fd7;
}

.text-block-10 {
	font-family: Karla, sans-serif;
	font-size: 1.375rem;
	line-height: 24px;
	font-style: italic;
}

.smartitems {
	display: none;
	width: auto;
	margin-top: 40px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.text-block-11 {
	font-family: Rubik, sans-serif;
	color: #002f55;
	font-weight: 500;
	text-align: left;
	letter-spacing: 1px;
}

.smartitem {
	padding-right: 20px;
	padding-left: 20px;
}

.smartitem.leftitem {
	padding-left: 0px;
	color: #fff;
}

.smartitem.middleitem {
	border-right: 1px solid #002f55;
	border-left: 1px solid #002f55;
}

.floatingcardholder {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-top: 30px;
	margin-left: 8%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	overflow: hidden;
}

.creditcardannouncement {
	margin-bottom: 15px;
}

.two-part-mobile-nav-wrapper {
	display: none;
}

.main-nav-link {
	position: relative;
	display: block;
	padding: 0.5rem 1.25rem;
	-webkit-transition: all 150ms ease;
	transition: all 150ms ease;
	color: #000;
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
}

.main-nav-link.w--current {
	background-color: rgba(3, 52, 102, 0.6);
	color: #f9b604;
}

.main-nav-link.mobile-duplicate {
	display: none;
}

.ncua-logo {
	display: block;
	width: 80px;
	height: 50px;
	margin-right: auto;
	margin-left: auto;
	background-image: url('/20170713122722/assets/images/NCUA.png');
	background-position: 50% 50%;
	background-size: 70px;
	background-repeat: no-repeat;
	text-align: center;
}

.ehl-logo {
	display: block;
	width: 80px;
	height: 50px;
	background-image: url('/20170713122722/assets/images/Equal-Housing.svg');
	background-position: 50% 50%;
	background-size: 50px;
	background-repeat: no-repeat;
}

.copyrightinformation {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.copyright {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 85%;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.creditcardbutton {
	border-radius: 4px;
	background-color: transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Rubik, sans-serif;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.creditcardbutton:hover {
	background-color: transparent;
}

.scroll-down-arrow {
	margin-top: 10px;
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
	color: #b65e11;
	font-size: 1.25rem;
	font-weight: 700;
}

.text-block-12 {
	font-family: Rubik, sans-serif;
	color: #b65e11;
	font-size: 0.75rem;
	font-weight: 500;
	text-align: center;
}

.scroll-arrow-holder {
	position: relative;
	width: 100%;
	height: 50px;
	z-index: 3000;
}

.scroll-arrow {
	position: absolute;
	opacity: 0;
	width: 100%;
}

.housing-logos {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 15%;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.routing-number {
	display: block;
	margin-right: 1.5rem;
	margin-bottom: 0.25rem;
	font-family: Karla, sans-serif;
	color: #fff;
	font-size: 0.9rem;
}

.routing-information {
	width: 100%;
}

.link {
	border-bottom: 2px solid hsla(0, 0%, 100%, 0.25);
}

.link:hover {
	border-bottom-color: transparent;
}

.whychooseinfo {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 45%;
	height: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-color: #fff;
}

.branch-photo {
	width: 55%;
	height: 100%;
	background-position: 100% 50%, 0px 0px, 50% 50%;
	background-size: cover, auto, cover;
	background-repeat: no-repeat, repeat, no-repeat;
}

.whychooseitem {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 10px;
	margin-left: 0px;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.whychooseheader {
	font-family: Karla, sans-serif;
	color: #002f55;
	font-size: 1.375rem;
	line-height: 24px;
	font-style: italic;
}

.whychooseblurb {
	margin-top: 10px;
	font-family: Karla, sans-serif;
	font-size: 1rem;
	text-align: left;
}

.div-block-9 {
	padding-right: 10%;
	padding-left: 0%;
}

.checkmark {
	width: 10%;
	height: 25px;
	margin-right: 10px;
	background-image: url('/20170713122722/assets/images/Check-Mark.svg');
	background-position: 50% 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
}

.whychoose-writeup {
	width: 90%;
}

.bold-text {
	border-bottom: 2px solid #eb8228;
}

.bold-text-2 {
	border-bottom: 2px solid #eb8228;
}

.link-wrap {
	margin-bottom: 1rem;
	color: #005590;
}

.left-navigation {
	display: inline-block;
	width: 21%;
	margin-top: 2rem;
	margin-left: 1.5rem;
	padding-bottom: 1rem;
	float: left;
	border-top: 5px none #ed1c29;
	background-color: transparent;
}

.table-styling {
	margin-bottom: 1rem;
	background-color: transparent;
}

.list-item {
	margin-bottom: 0.35rem;
	font-family: Karla, sans-serif;
}

.left-nav-link-level-1b {
	display: block;
	width: 100%;
	padding: 0.5rem 0.5rem 0.5rem 2rem;
	border-bottom: 1px solid rgba(18, 143, 215, 0.25);
	background-color: transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Rubik, sans-serif;
	color: #333;
	font-size: 0.75rem;
	font-weight: 400;
	text-decoration: none;
}

.left-nav-link-level-1b:hover {
	background-color: transparent;
	color: #128fd7;
}

.text-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px none #61bb46;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Rubik, sans-serif;
	color: #002f55;
}

.text-link:hover {
	border-bottom-color: transparent;
	color: #128fd7;
}

.paragraph-5 {
	font-family: Karla, sans-serif;
	font-size: 0.9375rem;
	line-height: 20px;
}

.breadcrumb-divider {
	display: inline-block;
	margin-right: 0.45rem;
	color: #747474;
	font-size: 0.65rem;
}

.block-quote-2 {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1.25rem;
	border-left-color: #6a9f30;
	font-family: Karla, sans-serif;
	font-size: 1.125rem;
	line-height: 1.5rem;
}

.breadcrumbs-list-item {
	display: inline-block;
}

.left-nav-link-level-1 {
	display: block;
	width: 100%;
	padding: 0.5rem;
	border-bottom: 1px solid rgba(18, 143, 215, 0.25);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Rubik, sans-serif;
	color: #002f55;
	font-weight: 500;
	text-decoration: none;
	text-transform: none;
}

.left-nav-link-level-1:hover {
	background-color: transparent;
	color: #128fd7;
}

.nested-unordered-list {
	margin-bottom: 0px;
}

.breadcrumb-link {
	display: inline-block;
	margin-right: 0.45rem;
	border-bottom: 1px solid rgba(18, 143, 215, 0.25);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Rubik, sans-serif;
	color: #747474;
	font-size: 0.65rem;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
}

.breadcrumb-link:hover {
	border-bottom-color: transparent;
	color: #eb8228;
}

.breadcrumb-link.w--current {
	border-bottom-color: transparent;
	color: #333;
}

.main-content {
	position: relative;
	display: block;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	min-height: 700px;
}

.main-content.inside-page-container {
	display: block;
	max-width: 1250px;
	margin-top: -3.5rem;
	background-color: #fff;
	opacity: 1;
}

.main-content.inside-page-container.no-banner-image {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 2rem;
}

.intro-paragraph {
	font-family: Karla, sans-serif;
	font-size: 1.25rem;
	line-height: 1.5rem;
	font-weight: 400;
}

.breadcrumbs-list {
	margin-bottom: 1rem;
	padding-left: 0px;
}

.h2-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px none #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Rubik, sans-serif;
	color: #002f55;
	font-size: 2rem;
	line-height: 2.5rem;
	font-weight: 500;
}

.h2-link:hover {
	border-bottom-color: transparent;
	color: #128fd7;
}

.left-nav-list-item {
	margin-right: 0rem;
	margin-left: 0rem;
}

.left-nav-list-item.no-right-margin {
	margin-right: 0rem;
	margin-left: 0rem;
}

.left-nav-list-item.half-margin-width {
	margin-right: 0rem;
	margin-left: 0rem;
	background-color: #557630;
	color: #fff;
}

.breadcrumbs {
	margin-bottom: 1.5rem;
}

.leftnavlinkblock {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.left-nav-list {
	padding-left: 0px;
}

.left-nav-section-title-link {
	display: block;
	padding: 0.5rem 1rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Rubik, sans-serif;
	color: #fff;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 500;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
}

.left-nav-section-title-link:hover {
	background-color: transparent;
}

.inside-body-section {
	background-color: transparent;
}

.left-nav-link-level-1a {
	display: block;
	width: 100%;
	padding: 0.5rem 0.5rem 0.5rem 1rem;
	border-bottom: 1px solid rgba(18, 143, 215, 0.25);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #002f55;
	font-weight: 600;
	text-decoration: none;
	font-family: Arial, sans-serif;
	font-size: 0.875rem;
	line-height: 20px;
}

.left-nav-link-level-1a:hover {
	background-color: transparent;
	color: #128fd7;
}

.heading-6 {
	font-family: Rubik, sans-serif;
	color: #002f55;
	font-weight: 500;
}

.left-nav-orange-dot {
	width: 10px;
	height: 10px;
	margin-bottom: 0.85rem;
	opacity: 0;
}

.ordered-list {
	margin-bottom: 1rem;
}

.main-content-wrap.with-left-sidebar {
	width: 74%;
	padding: 2rem 1.5rem;
	float: right;
}

.main-content-wrap.with-both-sidebars {
	width: 58%;
	padding-top: 2rem;
	padding-left: 1.5rem;
}

.main-content-wrap.with-right-sidebar {
	width: 78%;
	padding-top: 2rem;
	padding-left: 1.5rem;
}

.main-content-wrap.full-width {
	padding-top: 2rem;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
}

.secondary-button {
	height: auto;
	margin-top: 0px;
	margin-right: 5px;
	margin-left: 0px;
	border-radius: 4px;
	background-color: #6a9f30;
	-webkit-transition: all 450ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 450ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	font-family: Rubik, sans-serif;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.secondary-button:hover {
	background-color: #557630;
}

.primary-button {
	border-radius: 4px;
	background-color: #002f55;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Rubik, sans-serif;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.primary-button:hover {
	background-color: #128fd7;
}

.body {
	background-color: rgba(0, 47, 85, 0.1);
}

.right-sidebar-section-wrap {
	margin-top: 1rem;
	margin-bottom: 1rem;
	margin-left: 0rem;
	padding-right: 1rem;
	padding-left: 1.5rem;
	font-size: 1rem;
	line-height: 1.5rem;
}

.right-sidebar-section-title {
	margin-top: 0.5rem;
	padding-bottom: 0.5rem;
	border-bottom: 2px solid #557630;
	font-family: Rubik, sans-serif;
	color: #002f55;
	font-size: 1.125rem;
	line-height: 1.3755rem;
	font-weight: 500;
}

.right-sidebar-list-item {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid rgba(18, 143, 215, 0.25);
}

.link-2 {
	border-bottom: 1px none transparent;
	-webkit-transition: color 200ms ease, border-color 200ms ease;
	transition: color 200ms ease, border-color 200ms ease;
	font-family: Rubik, sans-serif;
	color: #128fd7;
	font-size: 0.875rem;
	font-weight: 500;
	text-decoration: none;
}

.link-2:hover {
	border-bottom: 1px none #c31d23;
	color: #eb8228;
}

.right-navigation {
	width: 21%;
	margin-top: 4rem;
}

html.w-mod-js *[data-ix="credit-card-slide-in"] {
	opacity: 0;
	-webkit-transform: translate(-100px, 0px);
	-ms-transform: translate(-100px, 0px);
	transform: translate(-100px, 0px);
}

html.w-mod-js *[data-ix="credit-card-info-slide-in"] {
	opacity: 0;
	-webkit-transform: translate(100px, 0px);
	-ms-transform: translate(100px, 0px);
	transform: translate(100px, 0px);
}

html.w-mod-js *[data-ix="icon-box-fade-in"] {
	opacity: 0;
	-webkit-transform: translate(0px, 40px);
	-ms-transform: translate(0px, 40px);
	transform: translate(0px, 40px);
}

@media (max-width: 991px) {
	.navbar {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.login {
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.login-form {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.topnavigationmenu {
		display: none;
	}

	.login-links-wrapper {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.loginlink {
		font-size: 0.75rem;
	}

	.slide {
		background-position: 50% 35%;
		background-size: cover;
	}

	.mask {
		height: 400px;
	}

	.topnavigationholder {
		padding-right: 5px;
		padding-left: 5px;
	}

	.nav-menu {
		display: block;
		margin-top: 0px;
		padding-top: 0px;
		padding-bottom: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		background-color: transparent;
		background-image: linear-gradient(150deg, #374c1e, #557630);
	}

	.nav-link {
		display: block;
		padding-bottom: 10px;
		padding-left: 30px;
		float: none;
		color: #fff;
		text-align: left;
	}

	.nav-link.mobileonly {
		display: block;
		padding-bottom: 10px;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
	}

	.nav-link.mobileonly:hover {
		background-color: hsla(0, 0%, 100%, 0.1);
	}

	.brand {
		position: absolute;
		left: 5%;
		top: 0%;
		right: auto;
		bottom: 0%;
		width: auto;
		height: 75px;
		margin-top: 10px;
	}

	.slider {
		height: 400px;
	}

	.header-search-container {
		right: 0px;
		display: none;
	}

	.searchicon {
		position: static;
		left: 0%;
		top: 0%;
		right: auto;
		bottom: 0%;
		z-index: 1000;
		display: none;
		margin-top: 91px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		grid-auto-columns: 1fr;
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		-ms-grid-rows: auto auto;
		grid-template-rows: auto auto;
	}

	.searchicon.mobilesearchicon {
		position: absolute;
		left: auto;
		top: 35px;
		right: 85px;
		bottom: 0%;
		display: block;
		width: 25px;
		height: 25px;
		margin-top: 5px;
		margin-right: 0px;
		margin-left: 0px;
		background-size: 20px 20px;
	}

	.icon {
		color: #557630;
		font-size: 2.5rem;
	}

	.menu-button {
		position: absolute;
		left: auto;
		top: 0%;
		right: 2%;
		bottom: 0%;
		padding-top: 32px;
	}

	.menu-button.current {
		background-color: transparent;
		color: #e22fe2;
	}

	.menu-button.current.w--open {
		background-color: transparent;
	}

	.icon-2 {
		margin-top: 175px;
		margin-right: 5px;
		font-size: 1.75rem;
	}

	.icon-3 {
		margin-top: 175px;
		margin-left: 5px;
		font-size: 1.75rem;
	}

	.iconboxes {
		padding-right: 20px;
		padding-left: 20px;
		background-position: 0px 0px, 100% 0%, 0% 0%;
	}

	.iconboxholder {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.iconbox {
		width: 100%;
	}

	.generalbutton.creditcards.leftaligned {
		margin-right: 0px;
		margin-left: 0px;
	}

	.generalbutton.creditcards.whychoosebutton {
		margin-right: 0px;
		margin-left: 0px;
	}

	.announcements {
		padding-right: 20px;
		padding-left: 20px;
	}

	.announcement {
		width: 30%;
	}

	.dropdownmegamenu {
		display: none;
	}

	.navlinkunderline {
		display: none;
	}

	.navlinkholder {
		display: none;
		margin-right: 0px;
		margin-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
	}

	.slideshowexplosion {
		width: 75%;
		padding-right: 25px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-position: 0% 100%;
		background-size: auto 100%;
	}

	.heading {
		margin-bottom: 5px;
		font-size: 1.5rem;
		line-height: 26px;
	}

	.text-block-3 {
		font-size: 1rem;
		line-height: 21px;
	}

	.captionholder {
		width: 50%;
		margin-bottom: 10px;
		margin-left: 5%;
		padding: 20px;
	}

	.slideshowbutton {
		margin-top: 10px;
	}

	.testimonials {
		padding-right: 20px;
		padding-left: 20px;
	}

	.headshot {
		width: 175px;
		height: 175px;
		margin-right: 30px;
	}

	.testimonialholder {
		padding-right: 0px;
		padding-left: 0px;
	}

	.whychooseus {
		height: auto;
		padding-right: 0px;
		padding-left: 20px;
	}

	.locations {
		padding-right: 20px;
		padding-bottom: 0rem;
		padding-left: 20px;
	}

	.featurednews {
		padding-top: 1rem;
		padding-right: 20px;
		padding-left: 20px;
	}

	.text-block-6 {
		line-height: 24px;
	}

	.subscribeandsocial {
		padding-right: 20px;
		padding-left: 20px;
		background-position: 50% 50%, 0px 0px;
		background-size: cover, auto;
	}

	.subscribetoemails {
		width: 65%;
	}

	.locationsholder {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: stretch;
		-ms-flex-line-pack: stretch;
		align-content: stretch;
	}

	.locationblock {
		width: 50%;
		margin-bottom: 20px;
	}

	.footer-signoff-link-wrap {
		display: block;
		margin-bottom: 1.5rem;
		float: left;
	}

	.footer-copyright {
		margin-right: 0rem;
		margin-bottom: 0.25rem;
		float: left;
	}

	.sign-off-div-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		min-width: auto;
		padding-right: 20px;
		padding-left: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.signoff-div {
		height: auto;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-signoff-links {
		margin-right: 0.75rem;
		margin-left: 0rem;
	}

	.creditcardheader {
		width: 100%;
	}

	.floatingcardholder {
		margin-top: 30px;
		margin-left: 0%;
	}

	.two-part-mobile-nav-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
	}

	.main-nav-link {
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		text-align: left;
	}

	.main-nav-link.mobile-duplicate {
		display: block;
		width: 88%;
		padding: 0.75rem 1.25rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom-style: none;
		background-color: transparent;
	}

	.main-nav-link.mobile-duplicate:hover {
		background-color: rgba(69, 120, 188, 0.35);
		background-image: none;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.main-nav-link.mobile-duplicate.w--current {
		box-shadow: inset 6px 0 0 0 #f9b604;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		color: #f9b604;
	}

	.main-nav-link.mobile-duplicate.nav-link {
		padding-left: 30px;
		font-size: 1rem;
		line-height: 20px;
		font-weight: 400;
	}

	.main-nav-link.mobile-duplicate.nav-link:hover {
		background-color: hsla(0, 0%, 100%, 0.1);
	}

	.mobile-menu-arrow {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 12%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-left: 1px solid hsla(0, 0%, 100%, 0.25);
		color: #000;
	}

	.mobile-menu-arrow:hover {
		background-color: hsla(0, 0%, 100%, 0.1);
	}

	.mobile-arrow {
		margin-right: 1.25rem;
		margin-left: 1.25rem;
		color: #fff;
	}

	.copyrightinformation {
		width: 100%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.copyright {
		width: 75%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.scroll-down-arrow {
		font-size: 1.75rem;
	}

	.housing-logos {
		width: 25%;
	}

	.routing-number {
		margin-right: 0rem;
		margin-bottom: 0.25rem;
		float: left;
	}

	.routing-information {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.whychooseinfo {
		width: 55%;
	}

	.branch-photo {
		width: 45%;
		height: 450px;
		background-position: 100% 50%, 0px 0px, 90% 50%;
	}

	.whychooseitem {
		margin-left: 0px;
	}

	.div-block-9 {
		padding: 1rem 0%;
	}

	.checkmark {
		margin-right: 5px;
	}

	.main-content {
		width: 100%;
	}

	.main-content-wrap.with-left-sidebar {
		display: block;
		width: 90%;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.right-sidebar-section-wrap {
		margin-left: 0rem;
	}

	.right-sidebar-section-title {
		font-size: 1.25rem;
	}
}

@media (max-width: 767px) {
	.login {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		overflow: visible;
		width: 100%;
		height: auto;
		margin-top: 0px;
		margin-right: 0px;
		margin-bottom: 0px;
		padding-top: 0px;
		padding-bottom: 0px;
		border-radius: 0px;
	}

	.login-form-wrapper {
		margin-top: 15px;
		margin-bottom: 15px;
	}

	.login-id-field {
		width: 125px;
		margin-right: 10px;
		margin-bottom: 0px;
		float: none;
	}

	.login-button {
		width: 75px;
		height: 25px;
		padding-top: 0px;
		padding-bottom: 0px;
		float: none;
		font-size: 0.75rem;
		font-weight: 400;
		text-transform: uppercase;
	}

	.mask {
		height: 300px;
	}

	.brand {
		left: 1%;
		width: 300px;
		height: auto;
		margin-top: 18px;
	}

	.slider {
		height: 300px;
	}

	.header-search-container {
		display: none;
	}

	.searchicon.mobilesearchicon {
		right: 75px;
	}

	.menu-button {
		right: 0%;
		padding-top: 30px;
	}

	.icon-2 {
		margin-top: 125px;
		margin-right: 5px;
	}

	.icon-3 {
		margin-top: 125px;
		margin-left: 5px;
	}

	.iconboxes {
		background-image: -webkit-gradient(linear, left bottom, left top, color-stop(43%, #fff), to(hsla(0, 0%, 100%, 0))), url('/20170713122722/assets/images/Side-Explosions-Right-01.svg'), url('/20170713122722/assets/images/Side-Explosions-01.svg');
		background-image: linear-gradient(0deg, #fff 43%, hsla(0, 0%, 100%, 0)), url('/20170713122722/assets/images/Side-Explosions-Right-01.svg'), url('/20170713122722/assets/images/Side-Explosions-01.svg');
		background-position: 0px 0px, 100% 0%, 0% 0%;
		background-size: auto, auto, auto;
		background-repeat: repeat, no-repeat, no-repeat;
	}

	.iconboxholder {
		padding-bottom: 2rem;
	}

	.iconbox {
		width: 100%;
	}

	.generalbutton {
		margin-left: 0px;
	}

	.creditcard {
		width: 100%;
	}

	.announcements {
		margin-top: 0rem;
		padding-right: 0px;
		padding-left: 0px;
	}

	.announcementsholder {
		padding-right: 20px;
		padding-left: 20px;
	}

	.announcement {
		width: 100%;
		padding-right: 60px;
		padding-left: 60px;
	}

	.slideshowexplosion {
		width: 100%;
		padding-right: 0px;
		background-image: none;
		background-position: 0px 0px;
		background-size: auto;
		background-repeat: repeat;
	}

	.heading {
		font-size: 1.25rem;
		line-height: 22px;
	}

	.text-block-3 {
		font-size: 0.875rem;
		line-height: 18px;
	}

	.captionholder {
		position: absolute;
		left: 0%;
		top: auto;
		right: 0%;
		bottom: 0%;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		margin-bottom: 0px;
		margin-left: 0px;
		padding: 15px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-radius: 0px;
		background-color: rgba(0, 47, 85, 0.75);
	}

	.slideshowbutton {
		padding: 5px 10px;
		font-size: 0.75rem;
		line-height: 20px;
	}

	.slideshowbutton:hover {
		padding-top: 5px;
		padding-bottom: 5px;
	}

	.slide-nav {
		display: none;
	}

	.div-block-5 {
		width: 70%;
	}

	.div-block-6 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 30%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.scrollingloginholder {
		right: 90px;
		display: none;
	}

	.testimonials {
		padding-right: 20px;
		padding-left: 20px;
	}

	.headshot {
		width: 150px;
		height: 150px;
		margin-right: 0px;
		margin-bottom: 20px;
	}

	.testimonialholder {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.testimonial {
		width: 100%;
	}

	.testimonialnamebuttonholder {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.text-block-5 {
		text-align: center;
	}

	.whychooseus {
		height: auto;
		padding-left: 0px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.featurednewscontent {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.featurednewsimage {
		width: 100%;
	}

	.text-block-6 {
		line-height: 24px;
	}

	.featurednewsblurbholder {
		width: 100%;
		margin-top: 20px;
		padding-left: 0px;
	}

	.subscribeandsocial {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.subscribetoemails {
		width: 85%;
		margin-right: auto;
		margin-left: auto;
	}

	.footer-signoff-link-wrap {
		display: inline-block;
		width: 100%;
		float: none;
	}

	.footer-copyright {
		float: none;
	}

	.signoff-div {
		height: auto;
		margin-bottom: 0px;
	}

	.footer-signoff-links.last {
		margin-right: 0rem;
	}

	.creditcardinfoholder {
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.creditcardheader {
		text-align: center;
	}

	.text-block-11 {
		text-align: center;
	}

	.smartitem.leftitem {
		padding-left: 20px;
	}

	.floatingcardholder {
		margin-left: 0%;
	}

	.creditcardannouncement {
		text-align: center;
	}

	.main-nav-link.mobile-duplicate {
		width: 84%;
		padding-left: 1rem;
	}

	.mobile-menu-arrow {
		width: 16%;
	}

	.ncua-logo {
		display: inline-block;
	}

	.ehl-logo {
		display: inline-block;
	}

	.copyright {
		width: 100%;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.housing-logos {
		display: block;
		width: 100%;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.routing-number {
		float: none;
	}

	.whychooseinfo {
		width: 100%;
		padding-top: 1rem;
		padding-bottom: 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.branch-photo {
		width: 100%;
		height: 310px;
	}

	.whychooseitem {
		margin-left: 60px;
	}

	.div-block-9 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-right: 10%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.ordered-list {
		padding-left: 20px;
	}

	.main-content-wrap.with-left-sidebar {
		padding-top: 1rem;
	}

	.secondary-button {
		margin-left: 0px;
	}
}

@media (max-width: 479px) {
	.navbar {
		height: 75px;
	}

	.login {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		overflow: hidden;
		width: 100%;
		height: auto;
		margin-right: 0px;
		padding-top: 0px;
		padding-bottom: 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-radius: 0px;
		opacity: 1;
	}

	.login-form-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 10px;
		margin-bottom: 10px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.login-form {
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		width: 270px;
	}

	.login-id-field {
		width: 55%;
		margin-bottom: 0px;
		float: none;
	}

	.login-button {
		width: 35%;
		float: none;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.login-links-wrapper {
		display: none;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.slide {
		background-position: 50% 35%;
	}

	.mask {
		height: 275px;
	}

	.topnavigationholder {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.nav-menu {
		margin-top: 0px;
	}

	.brand {
		width: 200px;
		height: 50px;
		margin-top: 15px;
	}

	.slider {
		height: 275px;
	}

	.header-search-container {
		display: none;
		width: 100%;
	}

	.search-form-wrap {
		width: 100%;
		background-color: hsla(0, 0%, 85.9%, 0.9);
	}

	.search-link {
		display: block;
		font-size: 0.875rem;
	}

	.searchicon {
		display: none;
	}

	.searchicon.mobilesearchicon {
		top: 20px;
		right: 55px;
	}

	.menu-button {
		padding-top: 18px;
	}

	.menu-button.current.open {
		padding-right: 10px;
	}

	.image {
		width: 200px;
		max-width: 200px;
	}

	.icon-2 {
		margin-top: 75px;
		margin-right: 0px;
	}

	.icon-3 {
		margin-top: 75px;
		margin-left: 0px;
	}

	.iconboxes {
		background-image: -webkit-gradient(linear, left bottom, left top, color-stop(49%, #fff), to(hsla(0, 0%, 100%, 0))), url('/20170713122722/assets/images/Side-Explosions-Right-01.svg'), url('/20170713122722/assets/images/Side-Explosions-01.svg');
		background-image: linear-gradient(0deg, #fff 49%, hsla(0, 0%, 100%, 0)), url('/20170713122722/assets/images/Side-Explosions-Right-01.svg'), url('/20170713122722/assets/images/Side-Explosions-01.svg');
		background-position: 0px 0px, 100% 0%, 0% 0%;
		background-size: auto, auto, auto;
		background-repeat: repeat, no-repeat, no-repeat;
	}

	.announcements {
		margin-top: 0rem;
	}

	.announcement {
		padding-right: 20px;
		padding-left: 20px;
	}

	.slideshowexplosion {
		background-image: none;
		background-position: 0px 0px;
		background-size: auto;
		background-repeat: repeat;
	}

	.heading {
		font-size: 1.125rem;
		text-align: center;
	}

	.text-block-3 {
		text-align: center;
	}

	.captionholder {
		padding: 10px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.div-block-5 {
		width: 100%;
	}

	.div-block-6 {
		width: 40%;
	}

	.locations {
		padding-bottom: 0.5rem;
	}

	.featurednews {
		padding-top: 1rem;
	}

	.text-block-6 {
		line-height: 24px;
	}

	.subscribesubmitinfo {
		margin-top: 0px;
	}

	.subscribetoemails {
		width: 97%;
	}

	.social-link.linkedin {
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
	}

	.social-link.facebook {
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
	}

	.social-link.twitter {
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
	}

	.locationblock {
		width: 100%;
		margin-bottom: 20px;
	}

	.footer-copyright {
		margin-bottom: 0.5rem;
		text-align: left;
	}

	.signoff-div {
		height: auto;
	}

	.footer-signoff-links.last {
		margin-right: 0rem;
	}

	.text-block-9 {
		padding-right: 100px;
		font-size: 1.375rem;
		line-height: 26px;
		text-align: left;
	}

	.creditcardheader {
		font-size: 0.75rem;
	}

	.text-block-10 {
		font-size: 1rem;
		line-height: 22px;
	}

	.text-block-11 {
		font-size: 0.75rem;
		line-height: 16px;
	}

	.smartitem.leftitem {
		padding-left: 20px;
	}

	.main-nav-link.mobile-duplicate {
		width: 80%;
	}

	.mobile-menu-arrow {
		position: relative;
		width: 20%;
	}

	.routing-number {
		margin-bottom: 0.5rem;
		padding-right: 0px;
		text-align: left;
	}

	.whychooseinfo {
		padding-top: 1rem;
	}

	.branch-photo {
		height: 200px;
	}

	.whychooseitem {
		margin-left: 0px;
	}

	.whychooseheader {
		font-size: 1.25rem;
		line-height: 22px;
	}

	.whychooseblurb {
		margin-top: 5px;
	}

	.div-block-9 {
		padding-right: 20px;
		padding-left: 20px;
	}

	.main-content-wrap.with-left-sidebar {
		padding-top: 1rem;
	}
}



/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Print ***********************/
/*************************************************/
#printHeader,
#printFooter {
	display: none;
}

/****************** Webflow ***********************/
/*************************************************/
body {
	overflow-x: hidden !important;
}

/********************* Header ********************/
/**************************************************/
@media (min-width: 992px) {
	.topnavigationmenu nav {
		display: flex;
	}

	.brand {
		float: left;
		display: flex;
		height: 100%;
	}

	.searchicon {
		align-self: center;
	}
}

/****************** Acesible menu *******************/
/*****************************************************/
.accesible-navigation-menu {
	display: flex;
	height: 100%;
	float: right;
	flex: 1;
}

.accesible-navigation-menu ul {
	margin: 0px;
	padding: 0px;
	align-self: center;
	height: 100%;
}

.accesible-navigation-menu ul li {
	margin-top: 0px;
	margin-bottom: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
}

.accesible-navigation-menu ul.nav-drop-list > li {
	padding-bottom: 15px;
}

.accesible-navigation-menu ul li.navlinkholder {
	width: auto;
	padding: 9px 0px 0px 0px;
	margin: 0px 5px 0px 5px;
	height: 100%;
}

.accesible-navigation-menu ul li a.nav-link,
.accesible-navigation-menu ul li span.nav-link {
	padding: 0px 7px 0px 7px;
	margin: 5px 0px 5px 0px;
	width: 100%;
	min-width: 70px;
}

.accesible-navigation-menu ul li .navlinkunderline {
	position: static;
	left: 0%;
	top: auto;
	right: 0%;
	bottom: 0%;
	width: 0px;
	height: 4px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-radius: 2px;
	background-color: #eb8228;
	content: "";
	display: inline-block;
	transition: width 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

.accesible-navigation-menu ul li:hover .navlinkunderline,
.accesible-navigation-menu ul li a.active.nav-link + .navlinkunderline,
.accesible-navigation-menu ul li span.active.nav-link + .navlinkunderline {
	width: 70px;
}

.accesible-navigation-menu .container-wrapper {
	position: absolute;
	width: 100%;
	left: 0;
	top: 100%;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#002f55), to(rgba(0, 47, 85, 0.9)));
	background-image: linear-gradient(180deg, #002f55, rgba(0, 47, 85, 0.9));
	min-height: 0px;
	transition: opacity 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
	opacity: 0;
	height: 0;
}

.accesible-navigation-menu ul li.navlinkholder.open .container-wrapper,
.accesible-navigation-menu ul li.navlinkholder .container-wrapper:hover {
	opacity: 1;
	height: auto;
	min-height: 160px;
}

.accesible-navigation-menu ul li.navlinkholder.open .container-wrapper ul.nav-drop-list,
.accesible-navigation-menu ul li.navlinkholder .container-wrapper:hover ul.nav-drop-list {
	display: flex;
	flex-wrap: wrap;
}

.accesible-navigation-menu .container-wrapper ul.nav-drop-list {
	float: right;
	margin: 0px;
	padding-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
	list-style-type: none;
	display: inline;
}

.accesible-navigation-menu .container-wrapper ul.nav-drop-list li {
	display: inline-block;
	float: left;
}

.accesible-navigation-menu .container-wrapper ul.nav-drop-list li a {
	margin-left: 0px;
	padding: 5px 40px 2px 0px;
	color: #fff;
	text-align: left;
	font-family: Rubik, sans-serif;
	font-size: 1rem;
	text-decoration: none;
	display: inline-block;
}

.accesible-navigation-menu .container-wrapper ul.nav-drop-list li a:hover {
	text-decoration: underline;
}

.accesible-navigation-menu .container-wrapper ul.nav-drop-list li ul {
	display: block;
}

.accesible-navigation-menu .container-wrapper ul.nav-drop-list li ul li {
	display: block;
	float: none;
}

.accesible-navigation-menu .container-wrapper ul.nav-drop-list li ul li a {
	margin-left: 0px;
	padding-top: 0px;
	padding-bottom: 2px;
	color: #ddd;
	font-size: 0.875rem;
	text-align: left;
}

.accesible-navigation-menu .container-wrapper ul.nav-drop-list {
	display: none;
}

@media (max-width: 991px) {
	.accesible-navigation-menu {
		display: none;
	}
}

/****************** Mobile navigation ****************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mobile-navigation-menu {
	background-color: #557630;
	background-image: linear-gradient(150deg, #374c1e, #557630);
}

.mobile-navigation-menu li a.nav-link {
	font-family: Rubik, sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 20px;
	font-weight: 400;
	float: none;
	text-align: left;
}

.mobile-navigation-menu li a.nav-link:hover,
.mobile-navigation-menu li.active a.nav-link {
	background-color: hsla(0, 0%, 100%, 0.1);
}

.mobile-navigation-menu .mm-listitem > a {
	padding: 12px 10px 12px 20px;
}

.mobile-navigation-menu .mm-listitem:after {
	left: 0;
}

.mobile-navigation-menu .mm-listitem,
.mm-listitem .mm-btn_next,
.mobile-navigation-menu .mm-listitem .mm-btn_next:before,
.mobile-navigation-menu .mm-listitem:after,
.mm-listview,
.mobile-navigation-menu .mm-navbar {
	border-color: hsla(0, 0%, 100%, 0.25);
}

.mobile-navigation-menu .mm-listview .mm-btn_next:after,
.mobile-navigation-menu .mm-btn:before {
	border-color: #fff;
}

.mobile-navigation-menu .mm-navbar {
	height: 45px;
}

.mobile-navigation-menu .mm-navbar__title {
	font-family: Rubik, sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 20px;
	font-weight: 400;
	text-align: left;
}

.mobile-navigation-menu .mm-navbar a,
.mobile-navigation-menu .mm-navbar > * {
	color: #fff;
}

.mobile-navigation-menu .mm-navbar > * {
	padding: 13px 0;
}

.mobile-navigation-menu .mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: -15px;
}

.mobile-navigation-menu .mm-btn_prev:before {
	left: 18px;
	top: 4px;
}

.mobile-navigation-menu .mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) {
	width: 70px;
}

.mobile-navigation-menu .mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) + a,
.mobile-navigation-menu .mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) + span {
	margin-right: 70px;
}

.mobile-navigation-menu .mm-btn_next:after {
	right: 32px;
}

.mobile-navigation-menu .mm-listitem_selected > a:not(.mm-btn_next),
.mobile-navigation-menu .mm-listitem_selected > span {
	background: transparent;
}

.mm-listitem > span {
	color: #fff;
}

/******************** Homepage **********************/
/*****************************************************/
.iconboxblurb ul li {
	text-align: left;
}

.text-block-11 {
	text-transform: uppercase;
}

.iconboxtitle a,
.iconboxtitle a:hover {
	font-weight: inherit;
	line-height: inherit;
	font-size: inherit;
	font-family: inherit;
	color: inherit;
	text-decoration: none;
}

.iconboxblurb p {
	font-family: Karla, sans-serif;
	font-size: 1rem;
	margin-top: 0px;
	margin-bottom: 0px;
}

.text-block-10 p,
.text-block-10 li,
.text-block-10 span {
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-weight: inherit;
	font-family: inherit;
}

.text-block-10 a {
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
}

.text-block-10 p:last-child {
	margin-bottom: 0px;
}

.testimonials * {
	color: #fff;
}

.testimonial p,
.testimonial li,
.testimonial td {
	font-family: Karla, sans-serif;
	color: #fff;
	font-size: 1.125rem;
	line-height: 23px;
	text-shadow: 1px 1px 6px #557630;
}

.whychooseitem {
	display: block;
}

ul.cb-check-list {
	margin: 0px 0px 0px 110px;
	padding: 0px;
}

.whychooseitem h2 {
	margin-bottom: 20px;
	font-family: Rubik, sans-serif;
	color: #002f55;
	font-size: 1.75rem;
	line-height: 32px;
	margin-top: 0px;
	font-weight: normal;
}

.whychooseitem .cb-more {
	margin-left: 130px;
}

.cb-underline {
	font-weight: bold;
	border-bottom: 2px solid #eb8228;
}

ul.cb-check-list li {
	margin-top: 10px;
	margin-bottom: 0px;
	font-family: Karla, sans-serif;
	font-size: 1rem;
	text-align: left;
	padding-left: 20px;
}

ul.cb-check-list li {
	list-style-image: url('/local/public/shared/assets/images/checkmark.png');
}

ul.cb-check-list li h2 {
	font-family: Karla, sans-serif;
	color: #002f55;
	font-size: 1.375rem;
	line-height: 24px;
	font-style: italic;
	margin: 0px 0px 10px 0px;
}

.blueheading3 a,
.blueheading3 a:hover {
	text-decoration: none;
	font-weight: inherit;
	line-height: inherit;
	font-family: inherit;
	color: inherit;
	font-size: inherit;
}

.text-block-7 p {
	font-weight: inherit;
	line-height: inherit;
	font-family: inherit;
	font-size: inherit;
	color: inherit;
}

.text-block-7 p:last-child {
	margin-bottom: 0px;
}

.nl-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.announcementblurb p {
	font-size: inherit;
}

.homepage-button {
	display: inline-block;
	line-height: inherit;
	text-decoration: none;
	height: auto;
	margin-top: 30px;
	margin-right: 5px;
	margin-left: 5px;
}

.homepage-button a {
	display: inline-block;
	padding: 9px 15px;
	color: #fff;
	border: 0;
	cursor: pointer;
	border-radius: 4px;
	background-color: #cccccc;
	-webkit-transition: all 450ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 450ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	font-family: Rubik, sans-serif;
	letter-spacing: 1px;
	text-transform: uppercase;
	text-decoration: none;
	text-align: center;
	font-size: 0.875rem;
}

.homepage-button a:hover {
	-webkit-transform: scale(1.01);
	-ms-transform: scale(1.01);
	transform: scale(1.01);
	color: #fff;
}

.iconbox.blue .homepage-button a {
	background-color: #002f55;
}

.iconbox.green .homepage-button a {
	background-color: #557630;
}

.iconbox.orange .homepage-button a {
	background-color: #b65e11;
}

.iconbox.yellow .homepage-button a {
	background-color: #9c6a07;
}

@media (max-width: 991px) {
	ul.cb-check-list {
		margin: 0px 0px 0px 50px;
	}

	ul.cb-check-list li {
		padding-left: 10px;
	}

	.whychooseitem .cb-more {
		margin-left: 60px;
	}
}

@media (max-width: 479px) {
	ul.cb-check-list li h2 {
		font-size: 1.25rem;
		line-height: 22px;
		margin-bottom: 5px;
	}

	.whychooseitem h2 {
		padding-right: 100px;
		font-size: 1.375rem;
		line-height: 26px;
		text-align: left;
	}
}

/********************** Slideshow *************************/
/**********************************************************/
.text-block-3 p,
.text-block-3 a,
.text-block-3 li,
.text-block-3 td {
	font-weight: inherit;
	line-height: inherit;
	font-family: inherit;
	font-size: inherit;
	color: inherit;
}

.text-block-3 p:last-child {
	margin-bottom: 0px;
}

/****************** Inside Page *******************/
/*************************************************/
td img {
	max-width: none;
}

img {
	height: auto;
	max-width: 100%;
	display: inline-block;
}

.left-nav-section-title-link {
	margin: 0px;
}

.left-nav-section-title-link a,
.left-nav-section-title-link a:hover {
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
	color: inherit;
	text-decoration: none;
	border-bottom: 0;
}

.breadcrumbs-list li:last-child .breadcrumb-divider {
	display: none;
}

.main-content.inside-page-container.no-banner-image {
	padding-bottom: 2rem;
}

.main-content-wrap .breadcrumbs {
	margin-bottom: 1.2rem;
}

@media (max-width: 991px) {
	.main-content.inside-page-container.no-banner-image {
		display: block;
	}

	.main-content-wrap.with-both-sidebars,
	.main-content-wrap.with-right-sidebar,
	.right-navigation {
		width: 100%;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
}

/******************* Right Sidebar *******************/
/*****************************************************/
.right-navigation .box {
	margin-top: 1rem;
	margin-bottom: 1rem;
	margin-left: 0rem;
	padding-right: 1rem;
	padding-left: 1.5rem;
}

.right-navigation .box h4,
.right-navigation .box h3 {
	margin-top: 0.5rem;
	padding-bottom: 0.5rem;
	border-bottom: 2px solid #557630;
	font-family: Rubik, sans-serif;
	color: #002f55;
	font-size: 1.125rem;
	line-height: 1.3755rem;
	font-weight: 500;
	margin-bottom: 10px;
}

.right-navigation .box td {
	vertical-align: top;
	padding-bottom: 0.5rem;
}

.right-navigation .box .contentbox_item_image {
	width: 24px;
}

.right-navigation .box.tools td:first-child img {
	margin-right: 5px;
}

.right-navigation .box.documents a,
.right-navigation .box.links a {
	border-bottom: 1px none transparent;
	-webkit-transition: color 200ms ease, border-color 200ms ease;
	transition: color 200ms ease, border-color 200ms ease;
	font-family: Rubik, sans-serif;
	color: #0f7ab8;
	font-size: 0.875rem;
	font-weight: 500;
	text-decoration: none;
}

.right-navigation .box.documents a:hover,
.right-navigation .box.links a:hover {
	color: #eb8228;
}

.right-navigation .box.documents table {
	width: 100%;
}

.right-navigation .box.documents table tr td:first-child {
	width: 24px;
	padding-right: 7px;
}

.right-navigation .box.documents table tr td {
	border-bottom: 1px solid rgba(18, 143, 215, 0.25);
	text-align: left;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.right-navigation .box.links p {
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
	display: inline-block;
	width: 100%;
	border-bottom: 1px solid rgba(18, 143, 215, 0.25);
}


/********************* Footer ********************/
/**************************************************/
#Footer a,
#Footer a:hover {
	color: #dbdbdb;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.signoff-div a,
.signoff-div a:hover {
	color: #fff;
	border-bottom: 1px solid transparent;
	text-decoration: none;
}

.routing-information {
	display: flex;
}

.routing-information > div {
	margin-right: 1.7%;
}

@media (max-width: 991px) {
	.mobile-tel {
		display: inline !important;
	}

	.routing-information > div {
		margin-bottom: 20px;
		margin-right: 0px;
	}

	.routing-information > div:last-child {
		margin-bottom: 0px;
	}

	.routing-number {
		float: none;
	}
}

@media (max-width: 767px) {
	.routing-information {
		margin-top: 10px;
	}
}

/****************** Inside Page Styles ***************/
/*****************************************************/
.cms_form_button.primary.skip-to-main-content.sronly {
	padding: 0 !important;
	border: 0px !important;
	line-height: 0;
}

.cms_form_button.primary.skip-to-main-content.sronly:focus {
	text-indent: 0;
	width: auto;
	height: auto;
	padding: 0.6rem 1rem !important;
	line-height: 1.1rem;
	box-shadow: 0 0 5px #000;
	z-index: 5000;
}

h1,
h2,
h3,
h4,
h5,
h5,
h6 {
	font-family: Rubik, sans-serif;
	color: #002f55;
	font-weight: 500;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	text-decoration: underline;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	color: #128fd7;
}

/* links */
a {
	text-decoration: underline;
	color: #002f55;
}

a:hover {
	color: #128fd7;
}

p {
	font-family: Karla, sans-serif;
	font-size: 0.9375rem;
	line-height: 20px;
}

td {
	font-family: 'Poppins', sans-serif;
}

li {
	margin-bottom: 0.35rem;
	font-family: Karla, sans-serif;
}

/* buttons */
.button,
.primary,
.cms_form_button.primary,
button[type="submit"],
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button,
.secondary,
.cms_form_button.secondary,
.tertiary,
.cms_form_button.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button {
	border-radius: 4px;
	background-color: #002f55;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Rubik, sans-serif;
	letter-spacing: 1px;
	padding: 9px 15px 7px 15px;
	color: #fff;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	border: 0px;
	border-bottom: 1px solid transparent;
}

.button:hover,
.primary:hover,
.cms_form_button.primary:hover,
button[type="submit"]:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button:hover,
.secondary:hover,
.cms_form_button.secondary:hover,
.tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button:hover {
	background-color: #128fd7;
	color: #fff;
	cursor: pointer;
}

.secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary {
	background-color: #557630;
	color: #fff !important;
	text-align: center;
}

.secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover {
	background-color: #6a9f30 !important;
	color: #fff;
}

.tertiary,
.cms_form_button.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	background-color: #b65e11;
	color: #fff !important;
	text-align: center;
}

.tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover {
	background-color: #d36818 !important;
	color: #fff;
}

/* messages */
#message.success {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-top: 2px solid #4fb402;
	border-bottom: 2px solid #4fb402;
	background-color: hsla(94, 73%, 76%, 0.55);
	font-weight: 500;
}

#message.error {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-top: 2px solid #e81c2e;
	border-bottom: 2px solid #e81c2e;
	background-color: rgba(255, 190, 195, 0.4);
	font-weight: 500;
}

#message.success p:last-child,
#message.error p:last-child {
	margin-bottom: 0px;
}

/*tables*/
table.styled {
	background-color: white;
}

table.styled th,
table.styled td {
	border-right: 1px solid #fff;
	vertical-align: top;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #002f55;
	color: white;
}

table.styled th {
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #557630;
	color: white;
}

table.styled th a {
	color: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	box-shadow: none;
}

table.styled th a:hover {
	color: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: lightgray;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.main-content input[type="text"],
.main-content input[type="tel"],
.main-content input[type="password"],
.main-content input[type="email"],
.main-content select,
.main-content textarea,
.main-content input[type="search"] {
	max-width: 100%;
	padding: 6px 6px;
	font-size: 0.875rem;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #666666;
}

.main-container textarea {
	resize: vertical;
}

label {
	font-weight: 500;
}

@media (max-width: 991px) {
	.main-content input[type="text"],
	.main-content input[type="tel"],
	.main-content input[type="password"],
	.main-content input[type="email"],
	.main-content select,
	.main-content textarea,
	.main-content input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	margin-top: 1rem;
	margin-bottom: 1rem;
	margin-right: 0px;
	margin-left: 0px;
	padding: 1rem 1.25rem;
	font-family: Karla, sans-serif;
	font-size: 1.125rem;
	line-height: 1.5rem;
	border-left: 5px solid #6a9f30;
}

blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
}


/*********************** Modules ********************/
/****************************************************/
/* events */
#events_module.cms_list .cms_divider {
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_list .cms_list_item .location-label-events-module {
	font-weight: 600;
}

#events_module.cms_list .cms_list_item .cms_date,
#events_module.cms_list .cms_date {
	font-size: 0.875rem;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

#events_module.cms_form h3 {
	margin-bottom: 0px;
}

#events_module.cms_form .cms_divider {
	margin-top: 5px !important;
	padding-top: 1px !important;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/* faqs */
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

/* locations */
#locations_module.list .locations_list_title,
#locations_module.list .location_types_title {
	border-color: hsla(0, 0%, 45%, 0.11);
}

#locations_module.list .type_item .title {
	font-size: 1.3rem;
}

/* news */
#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

#news_module.cms_entity .cms_content .image {
	display: block;
	margin: 0px 0px 10px 0px;
	float: none;
}

#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-bottom: 1rem;
	margin-top: 1.5rem;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}
}

/* rates */

/* submission forms */
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 3px;
	font-size: 0.9rem;
	color: #000;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

/* videos */
#video_module.cms_list .cms_list_item {
	padding-bottom: 1.5rem;
	margin-bottom: 1.5rem;
}

/* newsletter */
#newsletters_module.entity ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

#newsletters_module.entity h3,
#newsletters_module.entity h4 {
	margin-top: 0px;
}

#newsletters_module.form .form_item .field {
	padding-bottom: 15px;
}

#newsletters_module.list .item_divider {
	margin: 1.2rem 0px 1.2rem 0px;
}

@media (max-width: 991px) {
	#newsletters_module.form .form_item .label,
	#newsletters_module.form .form_item.required .label {
		width: auto;
		display: inline-block;
		text-align: left;
		padding-bottom: 5px;
	}

	#newsletters_module.form .form_item.required .label {
		background-image: url('/core/public/shared/assets/images/required.gif');
		background-repeat: no-repeat;
		background-position: right center;
		padding-right: 20px;
	}

	#newsletters_module.form .form_item .label label {
		display: inline-block;
	}

	#newsletters_module.form .form_item .field {
		width: 100%;
		display: block;
		padding-left: 0;
	}

	#newsletters_module.form .form_item.required .field {
		background: transparent none;
	}

	#newsletters_module.form .form_item .field table tr td {
		vertical-align: top;
	}

	#newsletters_module.form .form_buttons {
		text-align: left;
	}

	#newsletters_module.form .form_item input[type="text"] {
		width: 100% !important;
	}
}

/* testimonials */
#testimonials_module.cms_list .cms_list_item .cms_bottom,
#testimonials_module.cms_list .cms_list_item .cms_content {
	display: inline-block;
	width: 100%;
}

#testimonials_module.cms_list .cms_list_item .cms_author_name {
	font-weight: 600;
	font-size: 0.9rem;
}

/* mailing lists */
#mailinglists-module.form .form_subheading {
	border-bottom: 1px solid hsla(0, 0%, 45%, 0.11);
	margin: 12px 0 20px;
}

/* search */
.collection.search-collection .search-field {
	width: 100%;
	height: auto;
}

.collection.search-collection .node.search-node {
	margin-top: 1.2rem;
	padding-top: 1.2rem;
}

@media (max-width: 991px) {
	.module-search button {
		margin-top: 10px;
	}
}

/* accounts */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 256px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(140, 164, 189, 0.35);
	margin: 20px 0;
	padding-bottom: 7px;
	color: #002f55;
	font-size: 1.1rem;
	line-height: 1.3em;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url('/core/public/shared/assets/images/required.gif');
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/* staff */
#staffdirectory_module.cms_list .cms_list_item .image {
	padding-right: 15px;
	width: 150px;
}

#staffdirectory_module.cms_list .cms_list_item .image a img {
	max-width: 140px;
}

#staffdirectory_module.cms_list .cms_item_divider {
	border-top: 1px solid hsla(0, 0%, 66%, 0.35);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		float: none;
		margin-right: 0px;
		display: block;
	}
}

/***************** Content Boxes ********************/
/****************************************************/
/* testimonials */
#testimonials_module_contentbox.contentbox_item.withThumbnail .testimonials-item .headshot {
	width: 220px;
	height: 220px;
	margin-right: 40px;
	border: 0px;
	border-radius: 0px;
	background: none;
	box-shadow: none;
}

#testimonials_module_contentbox.contentbox_item.withThumbnail .testimonials-item .headshot img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-style: solid;
	border-width: 5px;
	border-color: hsla(0, 0%, 100%, 0.6);
	border-radius: 50%;
	box-shadow: 1px 1px 3px 0 #557630;
}

#testimonials_module_contentbox.contentbox_item.no-thumbnail .testimonial {
	width: 80%;
}

@media (max-width: 991px) {
	#testimonials_module_contentbox.contentbox_item.withThumbnail .testimonials-item .headshot img {
		width: 175px;
		height: 175px;
		margin-right: 30px;
	}

	#testimonials_module_contentbox.contentbox_item.withThumbnail .testimonials-item .headshot {
		width: 175px;
		height: 175px;
		margin-right: 30px;
	}
}

@media (max-width: 767px) {
	#testimonials_module_contentbox.contentbox_item.withThumbnail .testimonials-item .headshot img {
		width: 150px;
		height: 150px;
		margin-right: 0px;
		margin-bottom: 20px;
	}

	#testimonials_module_contentbox.contentbox_item.withThumbnail .testimonials-item .headshot {
		width: 150px;
		height: 150px;
		margin-right: 0px;
		margin-bottom: 20px;
	}

	#testimonials_module_contentbox.contentbox_item.no-thumbnail .testimonial {
		width: 100%;
	}
}

/* events */
#events_module.homepage_contentbox {
	text-align: left;
}

#events_module.homepage_contentbox .event_list {
	padding-bottom: 10px;
}

#events_module.homepage_contentbox .event_list .date {
	width: 100px;
}

#events_module.homepage_contentbox .event_list .title {
	width: calc(100% - 100px);
}

/* news */
.news-cb {
	text-align: left;
}

@media (max-width: 767px) {
	.news-cb {
		display: block;
	}
}

/* newsletter */

/* reates */
#rates_module.homepage_contentbox * {
	color: #333;
}

#rates_module.homepage_contentbox thead th {
	color: #fff;
}

/* testimonials */
.featurednewscontent #testimonials_module_contentbox .text-block-4,
.announcementblurb #testimonials_module_contentbox .text-block-4,
.whychooseitem #testimonials_module_contentbox .text-block-4,
.creditcardannouncement #testimonials_module_contentbox .text-block-4,
.iconboxblurb #testimonials_module_contentbox .text-block-4,
.slider #testimonials_module_contentbox .text-block-4,
.featurednewscontent #testimonials_module_contentbox .text-block-5,
.announcementblurb #testimonials_module_contentbox .text-block-5,
.whychooseitem #testimonials_module_contentbox .text-block-5,
.creditcardannouncement #testimonials_module_contentbox .text-block-5,
.iconboxblurb #testimonials_module_contentbox .text-block-5,
.slider #testimonials_module_contentbox .text-block-5 {
	text-shadow: none;
	color: #333;
	text-align: left;
}

.featurednewscontent #testimonials_module_contentbox .testimonialnamebuttonholder,
.announcementblurb #testimonials_module_contentbox .testimonialnamebuttonholder,
.whychooseitem #testimonials_module_contentbox .testimonialnamebuttonholder,
.creditcardannouncement #testimonials_module_contentbox .testimonialnamebuttonholder,
.iconboxblurb #testimonials_module_contentbox .testimonialnamebuttonholder,
.slider #testimonials_module_contentbox .testimonialnamebuttonholder {
	display: block;
	text-align: left;
}

/* videos */
.homepage-featured-video iframe {
	max-width: 100%;
}

/* submission form */
#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

#submissionforms_module.cms_form .cms_field textarea[cols="35"],
#submissionforms_module.cms_form .cms_field input[size="35"] {
	width: 289px;
}

#submissionforms_module.cms_form .cms_field textarea[cols="12"],
#submissionforms_module.cms_form .cms_field input[size="12"] {
	width: 128px;
}

@media (max-width: 991px) {
	#submissionforms_module.cms_form .cms_field textarea[cols="35"],
	#submissionforms_module.cms_form .cms_field textarea[cols="12"],
	#submissionforms_module.cms_form .cms_field input[size="35"],
	#submissionforms_module.cms_form .cms_field input[size="12"] {
		width: 100%;
	}
}
/****************** Google Translate *******************/
/*****************************************************/
.topnavigationlink {
	margin-right: 8px;
}

.login {
	margin-top: 18px;
}

.login-form-wrapper {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: auto;
}

.login-form {
	flex-wrap: wrap;
	flex-grow: 1;
	width: 220px;
}

.login-links-wrapper {
	display: block;
	width: 100%;
}

.header-dropdown-wrap {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	position: relative;
	z-index: 2000;
}

.header-translate-dropdown-toggle {
	padding: 0px 1.5em 0px 5px;
	color: #fff;
	border-radius: 4px;
	background-color: #002f55;
	font-family: Rubik, sans-serif;
	font-size: 0.75rem;
	line-height: 22px;
	font-weight: 400;
	text-align: center;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.header-translate-dropdown-toggle-text {
	padding-left: 2px;
	padding-top: 2px;
	padding-bottom: 1px;
}

.header-translate-dropdown-icon {
	margin-right: 0.6em;
	opacity: 0.5;
	font-size: 0.75em;
}

.google-translate {
	overflow: hidden;
	min-width: 169px;
	margin-top: 0px;
	margin-bottom: 0px;
	padding: 0px;
	color: #fff;
	font-size: 0.75rem;
	line-height: 1em;
	text-align: center;
}

.header-dropdown-wrap-inside:hover .header-translate-dropdown-toggle {
	background-color: #eb8228;
	color: #fff;
}

.header-dropdown-wrap-inside:hover .header-translate-dropdown-list {
	position: absolute;
	left: auto;
	display: block;
	right: 0px;
	padding: 4px;
	background-color: #f1f1f1;
	box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

@media (max-width: 991px) {
	.login {
		justify-content: center;
		margin-top: 0px;
	}
}

@media (max-width: 767px) {
	.login-form-wrapper {
		margin-top: 0px;
		margin-bottom: 0px;
	}
}

@media (max-width: 479px) {
	.topnavigationholder {
		padding-right: 0;
		padding-left: 0;
		height: 90px;
	}

	.login {
		overflow: visible;
		flex-wrap: wrap;
	}

	.login-form-wrapper {
		margin-bottom: 3px;
	}

	.header-dropdown-wrap {
		width: 100%;
		order: 1;
		margin-right: 0;
		margin-left: 0;
	}

	.login-form-wrapper {
		order: 2;
		flex-direction: column;
	}

	.header-translate-dropdown {
		width: 100%;
		margin-bottom: 10px;
	}

	.header-translate-dropdown-toggle {
		width: 100%;
	}

	.header-translate-dropdown-toggle {
		padding: 2px 1.5em 2px 5px;
	}
}

/* Accesibility widget */
body .userway.userway_p3 {
	bottom: 36px !important;
	right: 100px !important;
}


/* Alert Box */
.alert-wrap {
	text-align: center;
	background-color: #1f4d74;
	padding: 18px 10px;
}

.alert-wrap * {
	color: #fff;
}

.alert-wrap p {
	font-size: 1.25rem;
	font-weight: bold;
}

.alert-wrap p:last-child {
	margin-bottom: 0px;
}

.alert-wrap a {
	color: #fff;
	text-decoration: underline;
	font-weight: bold;
}

.alert-wrap a:hover {
	color: #fff;
	text-decoration: none;
}

/* Calculators */
#tvcFloatRightDivId {
	float: right;
}

#tvcInnerCalculatorDivId .InputContainer label:not(.tvcHideLabelClass) {
	display: inline;
}

#tvcInnerCalculatorDivId .InputContainer span.toggle input {
	margin-left: 10px;
}

.InputContainer label {
	font-size: 0.875rem !important;
}

.InputContainer .InputTable p {
	font-size: 0.875rem !important;
	margin-right: 10px;
}

.InputContainer #AmortizableLoansTable .toggle,
.InputContainer .InputTable .toggle {
	color: #333;
	border-spacing: 0;
	border-collapse: collapse;
	font-family: Karla, sans-serif;
	line-height: 22px;
	font-size: 0.875rem !important;
	box-sizing: border-box;
	vertical-align: top;
	white-space: nowrap;
	display: inline-table;
}

.InputContainer #AmortizableLoansTable .toggle input,
.InputContainer .InputTable .toggle input {
	vertical-align: middle;
	margin-right: 5px;
}

.InputContainer #AmortizableLoansTable .toggle input {
	margin-right: 0;
}

.InputContainer input[type="checkbox"] {
	vertical-align: middle;
}

.DetailContainer .cAmortContainer fieldset legend,
.DetailContainer .DetailTable fieldset legend {
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #002f55;
	color: #fff;
	width: 100%;
}

.DetailContainer .SubDetailTable th.Left {
	text-align: left;
}


.DetailContainer .SubDetailTable th,
.DetailContainer .SubDetailTable td,
.DetailContainer .cAmortTable td,
.DetailContainer .cAmortTable th {
	padding: 0.5rem 0.625rem;
}

.DetailContainer .SubDetailTable th[scope="col"] {
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
}

.DetailContainer .cAmortTable .cAmortHeader td {
	border-collapse: collapse;
	border-spacing: 0;
	box-sizing: border-box;
	vertical-align: top;
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
}

